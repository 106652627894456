<template>
  <EditAccount />
</template>

<script>
import EditAccount from '@/components/EditAccount';

export default {
  components: {
    EditAccount
  }
};
</script>
